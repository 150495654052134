<template>
  <div id="create-group">
    <h4>Pay ${{amount | money_format}} USD, learn {{group.name}} and get certified.</h4>
    <p>Embark on a language-learning journey by subscribing for just ${{amount | money_format}} USD per month and delve into mastering {{group.name}}</p>
    <div class="row justify-content-start">
        <div class="col-12">
            <div class="form-group">
                <label for="full_name">Your name</label>
                <input readonly name="full_name" class="form-control" :value="activeUser.first_name+' '+activeUser.last_name" type="text" id="full_name" placeholder="Your Name" />
            </div>
            <div class="form-group">
                <label for="emailaddress">Email address</label>
                <input readonly  name="emailaddress" :value="activeUser.email"  class="form-control" type="email" id="emailaddress" placeholder="Your Email" />
            </div>
            <div>
                <h5 class="mt-3">Amount: ${{amount | money_format}} USD</h5>
                <h5 class="mt-2">Billing period: Monthly</h5>
            </div>
            <div class="form-group text-left mt-3 mb-0">
                <button @click="payWithPaystack()" class="btn btn-block btn-primary" type="button">Pay Now!</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default{
    props:{
        group: {
            type: Object,
            required: true
        },
        hasAnySubscription:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            readyState: false,
            embed: false,
            scriptLoaded: null,
            amount: this.hasAnySubscription ? 1000: 2990,
        }
    },
    computed: {
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        paystackKey(){
            return this.$store.state.paystackKey
        },
    },
    methods: {
        loadScript(callback) {
            const script = document.createElement("script");
            script.src = "https://js.paystack.co/v1/inline.js";
            document.getElementsByTagName("head")[0].appendChild(script);
            if (script.readyState) {
                // IE
                script.onreadystatechange = () => {
                    if (script.readyState === "loaded" || script.readyState === "complete"){
                        script.onreadystatechange = null;
                        callback();
                    }
                };
            } else {
                // Others
                script.onload = () => {
                    callback();
                };
            }
        },
        payWithPaystack() {
            this.scriptLoaded &&
            this.scriptLoaded.then(() => {
                const paystackOptions = {
                    key: this.paystackKey,
                    email: this.activeUser.email,
                    firstname: this.activeUser.first_name,
                    lastname: this.activeUser.last_name,
                    channels: ["card"],
                    amount: this.amount,
                    callback: response => {
                        this.callback(response);
                    },
                    onClose: () => {
                        this.close();
                    },
                    metadata: {
                        first_name: this.activeUser.first_name,
                        last_name: this.activeUser.last_name,
                        group_id: this.group.id,
                        user_id: this.activeUser.id
                    },
                    currency: "NGN",
                };
                if (this.embed) {
                    paystackOptions.container = "paystackEmbedContainer";
                }
                const handler = window.PaystackPop.setup(paystackOptions);
                if (!this.embed) {
                    handler.openIframe();
                }
            });
        },
        callback(res){
            const formData = new FormData()
            formData.append('data', JSON.stringify(res))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post("/subscriptions/create", formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$emit('subscribed', response.data.data.group_id)
                    this.resetForm();
                }
            })
        },
        close(){
            this.alertError("You cancelled an operation")
        },
        resetForm() {
            this.$emit('closeMe');
        },
    },
    created(){
        this.scriptLoaded = new Promise(resolve => {
            this.loadScript(() => {
                resolve();
            });
        });
    },
    mounted() {
        if (this.embed) {
            // this.payWithPaystack();
        }
    },
}
</script>

