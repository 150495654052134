<template>
<div>
    <div class="mb-3">
        <div class="mb-2">
            <form class="form d-flex">
                <div class="mr-2 flex-grow-1">
                    <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" 
                    placeholder="Search..." class="form-control"></b-form-input>
                </div>
                <div class="">
                    <b-dropdown variant="secondary" right>
                        <template slot="button-content">
                            Filter <i class="mdi mdi-filter-variant"></i>
                        </template>
                        <b-dropdown-item-button @click="sortBy = 'asc'"> Ascending </b-dropdown-item-button>
                        <b-dropdown-item-button @click="sortBy = 'desc'"> Descending </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </form>
        </div>
        <div class=""><strong>Filters:</strong> Sort: <span class="text-uppercase">{{sortBy}}</span></div>
    </div>
    <is-loading v-if="isLoading" />
    <div v-else>
        <div class="row">
            <div class="col-md-6" v-for="(group, index) in groups" :key="index">
               <group-card :group="group">
                    <template v-slot:action>
                        <div>
                            <router-link v-if="group.belong" :to="`/languages/${group.id}`" class="btn-sm btn-primary">
                                Learn {{group.name}}
                            </router-link>
                            <b-button v-else @click.prevent="joinGroup(group)" size="sm" class="mr-2" variant="secondary">Add {{group.name}}</b-button>
                        </div>
                    </template>
               </group-card>
            </div>
            <div class="col-12 text-center" v-if="!groups.length">
                <span v-if="searchQuery">No language matching your search: <strong>{{searchQuery}}</strong></span>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-12 d-flex justify-content-center">
                <div class="pagination-rounded">
                    <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
            </div>
        </div>
    </div>
 
    <b-modal centered id="modal-1" v-model="popupSubscribe"  @hide="initializeAll()"
        title="Subscription required" header-close-variant="primary" title-class="font-18" hide-footer>
        <PaySubscription v-if="popupSubscribe"  @closeMe="initializeAll()"
         :hasAnySubscription="hasAnySubscription" :group="showItem"
         @subscribed="addGroupToList($event)"/>
    </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import PaySubscription from "@/components/forms/PaySubscription.vue"
import GroupCard from '@/components/cards/GroupCard.vue';
import _ from 'lodash';

export default {
    components:{
        IsLoading,
        PaySubscription,
        GroupCard
    },
    data() {
        return {
            isLoading: false,
            popupSubscribe: false,
            searchQuery: "",
            sortBy:'asc',
            showItem: null,
            hasAnySubscription: false
        };
    },
    watch: {
        sortBy (val) {
            this.fetchItems()
        }
    },
    computed: {
        pageData(){
            return this.$store.state.groupList.pageData
        },
        groups() {
            return this.pageData.data
        },
        currentPage: {
            get() {
                return this.pageData.current_page
            },
            set(val) {
                if(!this.pageData.current_page || this.pageData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.pageData.per_page || 10
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
    methods: {
        joinGroup(group){
            this.showItem = group
            Swal.fire({
                title: "Are you sure?",
                text: `You are about to add ${group.name} to your learning list.`,
                showCancelButton: true,
                confirmButtonColor: '#800020',
                cancelButtonColor: '#565656',
                confirmButtonText: 'Yes, Proceed',
            }).then((result) => {
                if (result.value) {
                    this.addGroupToList(group.id);
                }
            })
        },
        addGroupToList(groupId){
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.post(`/groups/${groupId}/join`)
            .then(response => {
                this.$store.dispatch("changeLoaderValue")
                if (response.data.success) {
                    if (response.data.data.interrupt) {
                        this.popupSubscribe = true,
                        this.hasAnySubscription = response.data.data.has_any_subscription
                    }else{
                        this.$store.commit("groupList/UPDATE_BELONG", response.data.data)
                    }
                }
            }) 
        },
        initializeAll(){
            this.showItem = null
            this.popupSubscribe = false
        },
        updateSearchQuery: _.debounce(function(string) {
            this.fetchItems()
        }, 2000),
        fetchItems(page, per_page = null){
            let payload = {page: page || 1, sort_by: this.sortBy, per_page: per_page || this.itemsPerPage}
            if(this.searchQuery){ payload.search = this.searchQuery }
            this.isLoading = true
            this.$store.dispatch("groupList/fetchGroups", payload)
            .then(response => this.isLoading = false)
        }
    },
    created() {
      this.fetchItems()
    },
};
</script>


